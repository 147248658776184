.topbar{
    height: 60px;
    width: 100%;
    position: sticky;
    padding: 30px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    top: 0;
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    border-bottom: 1px solid #cfcfcf;
}
.topbar-left{
    display: flex;
    align-items: center;
}
.logo{
    font-size: 20px;
    font-style:oblique;
    font-weight: bold;
    cursor: pointer;
    color: rgba(1, 35, 138, 0.952);
}
.topbar-right{
    display: flex;
    align-items: center;
    gap: 10px;
}
.topbar-icons{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    background:rgba(255, 0, 0, 0.164);
    color: rgba(182, 3, 3, 0.938);
    height: 35px;
    width: 35px;
    border-radius: 50%;
    .topbar-imgUser{
        object-fit: cover;
        width: 30px;
        height: 30px;
    }
}
.icons-user{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #c2c2c2bb;
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    border-radius: 50px;
    height: 35px;
    width: 108px;
    overflow: hidden;
    .username{
        font-size: 11px;
        color: rgba(1, 35, 138, 0.952);
        padding-right: 10px;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: 800;
        font-style:oblique;
    }
    
}
.topbar-not{
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 10px;
    top: -5px;
    padding: 3px;
    border-radius: 50%;
    background: red;
    font-size: 10px;
    color: white;
}
.topbar-img{
    height: 75px;
    width: 75px;
    border-radius: 50%;
    object-fit: cover;
}
.topbar-imgUser{
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%;
}
.topbar-icon{
    display: none;
}

@media screen and (max-width: 375px){
    .topbar{
        padding: 10px;
    }
    .logo{
        font-size: 13px;
    }
    .topbar-right{
        gap: 8px;
    }
    .topbar-icons{
        display: none;
    }
    .icons-user{
        width: 80px;
        height: 30px;
    }
    .username{
        font-size: 10px;
    }
    .topbar-icon{
        cursor: pointer;
        display: flex;
        border: 1px solid rgba(1, 35, 138, 0.952);
        color: rgba(1, 35, 138, 0.952);
        padding: 5px 8px;
        border-radius: 5px;
    }
    .colorRed{
        display: flex;
        cursor: pointer;
        border: 1px solid red;
        color: red;
        padding: 5px 8px;
        border-radius: 5px;
    }
}

@media screen and (max-width: 414px){
    .topbar{
        padding: 14px;
    }
    .topbar-img{
        height: 65px;
        width: 65px;
    }
    .logo{
        font-size: 13px;
    }
    .topbar-right{
        gap: 8px;
    }
    .topbar-icons{
        display: none;
    }
    .icons-user{
        width: 90px;
        height: 25px;
        overflow: hidden;
    }
    .username{
        font-size: 9px;
        padding-left: 5px;
    }
    .topbar-icon{
        cursor: pointer;
        display: flex;
        border: 1px solid rgba(1, 35, 138, 0.952);
        color: rgba(1, 35, 138, 0.952);
        padding: 5px;
        border-radius: 5px;
    }
    .close-icon{
        font-size: 9px;
    }
    .colorRed{
        display: flex;
        cursor: pointer;
        border: 1px solid red;
        color: red;
        padding: 5px;
        border-radius: 5px;
    }
}

@media screen and (max-width: 568px){
    .logo{
        font-size: 14px;
    }
    .topbar-icons{
        display: none;
    }
    .icons-user{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
    }
    .icon_ant{
        display: none;
    }
    .topbar-icon{
        cursor: pointer;
        display: flex;
        border: 1px solid rgba(1, 35, 138, 0.952);
        color: rgba(1, 35, 138, 0.952);
        padding: 5px;
        border-radius: 5px;
    }
    .close-icon{
        font-size: 9px;
    }

    .colorRed{
        display: flex;
        cursor: pointer;
        border: 1px solid red;
        color: red;
        padding: 5px;
        border-radius: 5px;
    }
}