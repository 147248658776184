@import '../../../style.scss';

.productForm{
    .product-container{
        .product-container-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            margin-bottom: 20px;
            border-radius: 5px;
            .product-left{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .product-h2{
                    font-size: 1rem;
                    color: rgb(1, 35, 138);
                    @include mobileXR{
                        font-size: .8rem;
                    }

                }
                span{
                    font-size: .8rem;
                    color: #6d6c6c;
                    @include mobileXR{
                        display: none;
                    }
                }
            }
        }
       .product-wrapper{
            background: #fff;
            padding: 40px;
            border-radius: 5px;
            @include mobileXR{
                padding: 20px;
            }
        .product-container-bottom{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            @include mobileXR{
                justify-content: center;
                grid-template-columns: repeat(1, 1fr);
                gap: 10px;
            }
            .form-controle{
                display: flex;
                flex-direction: column;
                gap: 20px;
                @include mobileXR{
                    gap: 10px;
                }
                label{
                    font-size: .9rem;
                    color: #797979;
                    @include mobileXR{
                        font-size: .8rem;
                    }
                }
                .form-input{
                    padding: 8px 10px;
                    border: none;
                    outline: none;
                    border: 1px solid #c5c5c5;
                    border-radius: 5px;
                    &:focus{
                        border: 1px solid rgba(36, 36, 250, 0.418);
                    }
                    @include mobileXR{
                        padding: 6px;
                    }
                }
                .form-radio{
                    display: flex;
                    gap: 6px;
                }
            }
        }
        .form-controle-desc{
            display: flex;
            flex-direction: column;
            gap: 20px;
            label{
                margin-top: 20px;
                font-size: .9rem;
                color: #797979;
                @include mobileXR{
                    font-size: .8rem;
                }
            }
            textarea{
                padding: 10px;
                border: none;
                outline: none;
                border: 1px solid #c5c5c5;
                border-radius: 5px;
                resize: none;
                height: 120px;
                &:focus{
                    border: 1px solid rgba(36, 36, 250, 0.418);
                }
            }
        }
        .form-controleFile{
            width: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            label{
                font-size: .9rem;
                color: #797979;
                padding-bottom: 20px;
                @include mobileXR{
                    font-size: .8rem;
                }
            }
            .form-file{
                width: 100%;
                border: 1px dashed #c5c5c5;
                background: #f7f7f7;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height:150px;
                gap: 10px;
                border-radius: 5px;
                cursor: pointer;
                &:focus{
                    border: 1px solid rgba(36, 36, 250, 0.418);
                }
                label{
                    font-size: .9rem;
                    color: #797979;
                }
                .cloud-icon{
                    color: rgba(14, 62, 204, 0.719);
                    font-size: 2.8rem;
                }
                span{
                    font-size: .9rem;
                    color: #929292;
                    letter-spacing: 1px;
                }
            }
        }
        .form-img{
            height: 150px;
            width: 150px;
            border: 1px solid #c5c5c5;
            position: relative;
            overflow: hidden;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .capture-img{
                height: 100%;
            }
        }
        .form-submit{
            display: flex;
            gap: 10px;
            margin-top: 15px;
            .btn-submit{
                padding: 15px 20px;
                border: none;
                color: #fff;
                background:rgb(1, 35, 138);
                border-radius: 5px;
                cursor: pointer;
                transition: .5s;
                &:hover{
                    background:rgb(2, 55, 214);
                }
                @include mobileXR{
                    padding: 10px 15px;
                }
            }
            .btn-annuler{
                background: #464444;
                cursor: pointer;
            }
        }
       }
       
    }
}