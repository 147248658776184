@import '../../style.scss';

.informatiqueGeneral{
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include mobileXR{
        width: 100%;
    }
}