.retourForm{
    .product-container{
        .product-container-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            margin-bottom: 20px;
            border-radius: 5px;
            .product-left{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .product-h2{
                    font-size: 1rem;
                    color: rgb(1, 35, 138);

                }
                span{
                    font-size: .8rem;
                    color: #6d6c6c;
                }
            }
        }
       .product-wrapper{
            background: #fff;
            padding: 40px;
            border-radius: 5px;
        .product-container-bottom{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            .form-controle{
                display: flex;
                flex-direction: column;
                gap: 20px;
                label{
                    font-size: .9rem;
                    color: #797979;
                }
                .form-input{
                    padding: 10px;
                    border: none;
                    outline: none;
                    border: 1px solid #c5c5c5;
                    border-radius: 5px;
                }
            }
        }
        .form-controleFile{
            width: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            label{
                font-size: .9rem;
                color: #797979;
                padding-bottom: 20px;
            }
            .form-file{
                width: 100%;
                border: 1px solid #c5c5c5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height:150px;
                gap: 10px;
                border-radius: 5px;
                cursor: pointer;
                label{
                    font-size: .9rem;
                    color: #797979;
                }
                .cloud-icon{
                    color: rgb(1, 35, 138);
                    font-size: 1.8rem;
                }
                span{
                    font-size: .9rem;
                    color: #929292;
                    letter-spacing: 1px;
                }
            }
        }
        .form-submit{
            display: flex;
            gap: 10px;
            padding-top: 30px;
            .btn-submit{
                padding: 15px 20px;
                border: none;
                color: #fff;
                background:rgb(1, 35, 138);
                border-radius: 5px;
                cursor: pointer;
            }
            .btn-annuler{
                background: #464444;
                cursor: pointer;
            }
        }
       }
       
    }
}