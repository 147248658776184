@import '../../style.scss';

.varianteProduit{
    .varianteProduit-wrapper{
        .varianteProduit-container-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 20px;
            border-radius: 5px;
            @include mobileXR{
                padding: 10px;
            }
            .varianteProduit-left{
                
                .varianteProduit-h2{
                    font-size: 1rem;
                    color: rgb(1, 35, 138);
                    @include mobileXR{
                        font-size: .8rem;
                    }
                }
                span{
                    font-size: .8rem;
                    color: #6d6c6c;
                    @include mobileXR{
                        font-size: .7rem;
                    }
                }
            }
        }
        .variant-container-bottom{
            width: 100%;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 20px;
            border-radius: 5px;
            .variant_top{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                @include mobileXR{
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                }
                .variant-top-left{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .variant-top-row{
                        display: flex;
                        align-items: center;
                        gap: 3px;
                        .variant-icon{
                            font-size: .7rem;
                            color: #6d6c6c;
                            @include mobileXR{
                                font-size: .6rem;
                            }
                            
                        }
                        span{
                            font-size: .8rem;
                            color: #6d6c6c;
                            font-weight: 300;
                            @include mobileXR{
                                font-size: .7rem;
                            }
                        }
                    }
                    .variant-select{
                        width: 110px;
                        z-index: 888;
                        @include mobileXR{
                            width: 100px;
                        }
                    }
                }
            }
            .variant_bottom{
                width: 100%;
                margin-top: 40px;
                .variante-top-rows{
                    padding: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .variante-top-row{
                        flex: 1;
                        position: relative;
                        margin: 5px;
                        min-width: 280px;
                        height: 300px;
                        background: #f5fbfd;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        .cercle{
                            height: 200px;
                            width: 200px;
                            border-radius: 50%;
                            background: #fff;
                            position: absolute;
                        }
                        .variante-img{
                            height: 75%;
                            z-index: 2;
                            object-fit: cover;
                            &:hover{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }
}