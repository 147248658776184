.rapportDepense{
    width: 100%;
    padding: 20px 0;
    .rapport-h1{
        font-size: 22px;
        font-weight: bold;
        color: rgba(1, 35, 138, 0.952);
        padding: 0 20px 20px 0px;
        text-transform: uppercase;
    }
    .rapportDepense-wrapper{
        width: 100%;
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        margin-bottom: 20px;
        border-radius: 5px;
        padding: 20px;
    }
}