@import '../../style.scss';

.rowTotalDetail{
    width: 100%;
    .rowTotalDetail-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        @include mobileSE{
            width: 100%;
            justify-content: center;
        }
        @include mobileXR{
            display: grid;
            grid-template-columns: repeat(2,1fr);
        }
        .rowTotalDetail-row{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 20px;
            color: #fff;
            cursor: pointer;
            .rowTotalDetail-left{
                display: flex;
                flex-direction: column;
                gap: 5px;
                .rowTotal-h2{
                    font-size: 2rem;
                }
                .rowTotal-span{
                    font-size: 1rem;
                }
            }
            .rowTotalDetail-right{
                .rowTotalIcon{
                    font-size: 2.5rem;
                }
            }
        }
    }
}