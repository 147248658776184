@import '../../style.scss';

.pageDetails{
    .pageDetail-wrapper{
        .pageDetail-container{
            display: flex;
            justify-content: space-between;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 30px;
            border-radius: 5px;
            gap: 20px;
            @include mobileXR{
                flex-direction: column;
            }
            .pageDetail-left{
                flex: 1;
                .pageDetail-img{
                    position: relative;
                    height: 350px;
                    width: 100%;
                    object-fit: cover;
                    @include mobileXR{
                        width: 300px;
                    }
                }
                .pageDetail-left-wrapper{
                    padding: 20px 0;
                    h2{
                        color: rgb(3, 3, 109);
                        font-weight: bolder;
                        padding: 20px 0;
                    }
                    ul{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        li{
                            display: flex;
                            align-items: center;
                            gap: 5px;
                        }
                        .Noir{
                            height: 20px;
                            width: 20px;
                            background: black;
                            gap: 30px;
                            border-radius: 50%;
                        }
                        .Gris{
                            height: 20px;
                            width: 20px;
                            background: gray;
                            gap: 30px;
                            border-radius: 50%;
                        }
                        .Bleu{
                            height: 20px;
                            width: 20px;
                            background: blue;
                            gap: 30px;
                            border-radius: 50%;
                        }
                        .Rouge{
                            height: 20px;
                            width: 20px;
                            background: red;
                            gap: 30px;
                            border-radius: 50%;
                        }
                        .Vert{
                            height: 20px;
                            width: 20px;
                            background: green;
                            gap: 30px;
                            border-radius: 50%;
                        }
                        .Marron{
                            height: 20px;
                            width: 20px;
                            background: brown;
                            gap: 30px;
                            border-radius: 50%;
                        }
                        .Orange{
                            height: 20px;
                            width: 20px;
                            background: orangered;
                            gap: 30px;
                            border-radius: 50%;
                        }
                        .Rose{
                            height: 20px;
                            width: 20px;
                            background: deeppink;
                            gap: 30px;
                            border-radius: 50%;
                        }
                        .Beige{
                            height: 20px;
                            width: 20px;
                            background: beige;
                            gap: 30px;
                            border-radius: 50%;
                        }
                    }
                }

            }
            .pageDetail-right{
                flex: 1;
                .pageDetail-h2{
                    font-size: 2.5rem;
                    font-weight: bolder;
                }
                .pagedetailDescr{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    span{
                        font-size: .9rem;
                    }
                    a{
                        color: rgb(161, 161, 161);
                        font-size: .8rem;
                    }
                }
                .pageEtoile-row{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    padding: 15px 0;
                    a{
                        color: rgb(161, 161, 161);
                        font-size: .8rem;
                    }
                }
                .pageDetail-bottom{
                    border-top: 1px solid rgb(170, 170, 170);
                    border-bottom: 1px solid rgb(180, 179, 179);
                    .pageDetail-rows-prix{
                        padding: 25px 0;
                        h3{
                            font-size: 1rem;
                            color: rgb(150, 150, 150);
                            letter-spacing: 1.5;
                            font-weight: 300;
                        }
                        .pageDetail-images{
                            display: flex;
                            align-items: center;
                            gap: 20px;
                            .pageDetail-sous-image{
                                height: 75px;
                                width: 70px;
                                object-fit: cover;
                            }
                            .pageDetail-select{
                                width: 200px;
                                padding: 10px;
                                border: 1px solid rgb(233, 233, 233);
                                border-radius: 5px;
                                outline: none;
                            }
                        }
                    }
                }
                .pageDetail-rows-prix{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 15px 0;
                    .pageDetail-row-prix{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h3{
                            color: rgb(3, 3, 109);
                            font-size: 1.8rem;
                        }
                        span{
                            font-size: .7rem;
                            color: rgb(138, 138, 138);
                        }
                    }
                    .pageDetail-row-Qt{
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        .pageDetail-rows-Qt{
                            flex: 1;
                            display: flex;
                            span{
                                flex: 2;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid rgb(231, 231, 231);
                            }
                            .rowQT{
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                span{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    outline: none;
                                    cursor: pointer;
                                    transition: .5s;
                                    background: rgba(2, 2, 129, 0.925);
                                    color: #fff;
                                    &:hover{
                                        transform: scale(1.1);
                                    }
                                }
                            }
                        }
                        button{
                            flex: 4;
                            border: none;
                            padding: 15px;
                            background: rgba(2, 2, 129, 0.925);
                            color: #fff;
                            border-radius: 5px;
                            cursor: pointer;
                            transition: .5s;
                            &:hover{
                                background: rgba(9, 9, 209, 0.925);
                            }

                        }
                    }
                    .detail-inventaire{
                        .detail-h2{
                            color: rgb(3, 3, 109);
                            font-weight: bolder;
                            padding: 15px 0;
                        }
                        table{
                            width: 100%;
                            border-collapse: collapse;
                            border: 2px solid rgb(224, 224, 224);
                            letter-spacing: 1px;
                            font-size: 0.7rem;
                            tr{
                                &:nth-child(odd) {
                                    background-color: #eeeeeecb;
                                  }
                                
                                td,th{
                                    border: 1px solid rgb(216, 216, 216);
                                    padding: 10px;
                                    text-align: center;
                                    td {
                                        text-align: center;
                                      }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}