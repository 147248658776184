.formMouvementRows{
    display: flex;
    gap: 10px;
    .product-wrapper{
        flex: 3;
    }
    .mouvement-left{
        flex: 2;
        padding: 20px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        margin-bottom: 20px;
        border-radius: 5px;
        .mouvement-title{
            font-weight: 300;
            font-size: 1.5rem;
            color: #6d6c6c;
            letter-spacing: 1.5px;
            margin-bottom: 40px;
        }
    }
}