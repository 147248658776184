@import '../../../style.scss';

.pageLivreurDetail{
    @include mobileXR{
        width: 100%;
        background: #fff;
        margin: auto;
        @include mobileSE{
            width: 100%;
            margin: auto;
        }
    }
    .pageLivreurDetail-wrapper{
        @include mobileXR{
            padding: 20px;
            width: 100%;
        }
        .img-page-livreur{
            @include mobileXR{
                width: 100%;
                height: 350px;
                object-fit: cover;
            }
        }
        .pageLivreurDetail-rows{
            @include mobileXR{
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
            .pageLivreur-name{
                @include mobileXR{
                    color: #555;
                    padding: 10px 0;
                    border-bottom: 1px solid #d6d6d6;
                }
            }
        }
    }
}