.mouvClient{
    margin-top: 30px;
    padding: 20px;
    .mouvWrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        gap: 20px;
        .mouvClient-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid rgb(236, 236, 236);
            span{
                flex: 1;
                width: 100%;
                font-size: 1rem;
                color: #555;
            }
        }
    }
}