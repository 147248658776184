@import '../../style.scss';

.right-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    @include mobileXR{
        padding: 10px;
    }
    .rightRows{
        display: flex;
        gap: 20px;
        @include mobileXR{
            justify-content: center;
        }
        .rightbar-left{
            flex: 4;
            background: #fff;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            border-radius: 5px;
            padding: 20px;
            @include mobileXR{
                display: none;
            }
        }
        .rightbar-right{
            flex: 2;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            border-radius: 5px;
            background: #fff;
            padding: 20px;
            @include mobileXR{
                width: 260px;
            }
        }
    }
}