@import '../../../style.scss';
.detailProduitCommande{
    .detailProduit-wrapper{
        .detailProduit-container-top{             display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 10px;
            border-radius: 5px;
            .detailProduit-left{
                .detailProduit-h2{
                    font-size: .9rem;
                    color: rgb(1, 35, 138);
                }
                span{
                    font-size: .8rem;
                    color: #6d6c6c;
                }
            }
        }
        .detail-container-bottom{
            width: 100%;
            background: #fff;
/*             -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75); */
/*             padding: 10px; */
            border-radius: 5px;
            .detail-container-rows{
                display: flex;
                @include mobileXR{
                    flex-direction: column;
                }
                .detail-bottom-left{
                    flex:1;
                    padding: 20px 40px 10px 10px;
                    @include mobileXR{
                        padding-bottom: 10px;
                    }
                    .detail-bottom-img{
                        width: 100%;
                        height: 18%;
                        object-fit: cover;
                        @include mobileXR{
                            width: 290px;
                        }
                    }
                }
                .detail-bottom-right{
                    flex: 1;
                    padding: 40px 0;
                    .product-titre{
                        color: rgb(3, 3, 109);
                        font-weight: bolder;
                        font-size: 25px;
                        @include mobileXR{
                            font-size: 20px;
                        }
                    }
                    .product-desc{
                        margin: 10px 0px;
                        line-height: 1.6;
                        font-weight: 300;
                        text-align: justify;
                        @include mobileXR{
                            margin: 8px 0px;
                        }
                    }
                    .product-rate{
                        padding: 10px 0;
                        border-top: 1px solid #d3d3d3;
                        border-bottom: 1px solid #d3d3d3;
                    }
                    .product-price{
                        color: rgb(3, 3, 109);
                        font-weight: bolder;
                        font-size: 40px;
                        @include mobileXR{
                            font-size: 30px;
                        }
                    }
                    .filter-products{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        margin: 10px 0;
                        .filter-product-row{
                            width: 100%;
                            display: flex;
                            gap: 50px;
                            @include mobileXR{
                                gap: 25px;
                            }
                        }
                        .filters{
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            .filter-titre{
                                font-size: 18px;
                                font-weight: 300;
                                color: #222121;
                            }
                            .filter-color{
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                            }
                            .Noir{
                                height: 20px;
                                width: 20px;
                                background: black;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Gris{
                                height: 20px;
                                width: 20px;
                                background: gray;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Bleu{
                                height: 20px;
                                width: 20px;
                                background: blue;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Rouge{
                                height: 20px;
                                width: 20px;
                                background: red;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Vert{
                                height: 20px;
                                width: 20px;
                                background: green;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Marron{
                                height: 20px;
                                width: 20px;
                                background: brown;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Rose{
                                height: 20px;
                                width: 20px;
                                background: #FFC0CB;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Chocolat{
                                height: 20px;
                                width: 20px;
                                background: chocolate;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .select-filter{
                                padding: 6px 10px;
                                border: 1px solid rgb(221, 221, 221);
                                outline: none;
                                @include mobileXR{
                                    padding: 4px 7px;
                                }
                                .taille_pla{
                                    @include mobileXR{
                                        font-size: 5px;
                                    }
                                }
                            }
                        }
                        .detail_client-row{
                            width:100%;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            margin-top: 10px;
                            padding: 20px 0;
                            border-top: 1px solid #d3d3d3;
                            border-bottom: 1px solid #d3d3d3;
                            label{
                                font-size: 1rem;
                                font-weight: 300;
                            }
                            select{
                                padding: 6px 10px;
                                border: 1px solid rgb(221, 221, 221);
                                outline: none;
                            }
                        }
                        .filter-product{
                            display: flex;
                            align-items: center;
                            gap: 40px;
                            width: 100%;
                            margin: 40px 0;
                            @include mobileXR{
                                margin: 25px 0;
                            }
                            .filter{
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                .filter-icon{
                                    font-size: 20px;
                                    cursor: pointer;
                                    margin: 0 5px;
                                    color: rgb(3, 3, 109);
                                }
                                .filter-nb{
                                    font-size: 20px;
                                    border-radius: 20%;
                                    padding: 8px;
                                    border: 1px solid lightgray;
                                    color: rgb(3, 3, 109);
                                }
                                .filter-btn{
                                    border: none;
                                    font-size: 13px;
                                    font-weight: 600;
                                    border-radius: 5px;
                                    background: transparent;
                                    padding: 10px;
                                    border: 2px solid rgba(1, 35, 138, 0.952);
                                    color: rgba(1, 35, 138, 0.952);
                                    transition: .5s;
                                    cursor: pointer;
                                    &:hover{
                                        background: black;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .detail-rows-bottom{
                border: 1px solid #d3d3d3;
                padding: 20px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include mobileXR{
                    font-size: 12px;
                }
            }
        }
    }
}