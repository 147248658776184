@import '../../../style.scss';
.clientFormTel{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .product-container{
        width: 100%;
        padding: 20px;
        .product-wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .product-container-bottom{
                width: 100%;
                .form-controle{
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    width: 100%;
                    label{
                        font-size: .9rem;
                        color: #797979;
                        @include mobileXR{
                        font-family: .8rem;
                        }
                    }
                    .form-input{
                        padding: 10px;
                        border: none;
                        outline: none;
                        border: 1px solid #c5c5c5;
                        border-radius: 5px;
                    }
                }
            }
            .form-submit{
                padding-top: 15px;
                .btn-submit{
                    padding: 15px 20px;
                    border: none;
                    color: #fff;
                    background:rgb(1, 35, 138);
                    border-radius: 5px;
                    cursor: pointer;
                    &:hover{
                        background:rgb(3, 58, 223);
                    }
                }
            }
        }
    }
}