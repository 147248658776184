@import '../../../style.scss';

.productView{
    .product-wrapper{
        .product-container-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 20px;
            border-radius: 5px;
            .product-left{
                .product-h2{
                    font-size: 1rem;
                    color: rgb(1, 35, 138);
                    @include mobileXR{
                        font-size: .8rem;
                    }
                }
                span{
                    font-size: .8rem;
                    color: #6d6c6c;
                    @include mobileXR{
                        display: none;
                    }
                }
            }
        }
        .product-bottom{
            display: flex;
            gap: 20px;
            @include mobileXR{
                flex-direction: column;
            }
            .product-view-left{
                flex: 4;
                padding: 30px;
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                margin-bottom: 20px;
                border-radius: 5px;
                table{
                    width: 100%;
                    border-collapse: collapse;
                    border: 2px solid rgb(224, 224, 224);
                    letter-spacing: 1px;
                    font-size: 0.8rem;
                    tr{
                        &:nth-child(odd) {
                            background-color: #eeeeeecb;
                          }
                        
                        td,th{
                            border: 1px solid rgb(216, 216, 216);
                            padding: 15px;
                            td {
                                text-align: center;
                              }
                        }
                        th[scope='col'] {
                            background:rgba(1, 41, 160, 0.726);
                            color: #FFF;
                            font-weight: 600;
                          }
                          
                          th[scope='row'] {
                            color: #444444;
                            width: 30%;
                            font-weight: 500;
                          }
                    }
                }
                .product_view_form{
                    padding: 20px 0;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    @include mobileXR{
                        padding: 10px 0;
                    }
                    .product-h2{
                        font-weight: 300;
                        font-size: 1.5rem;
                        color: #797979;
                        padding-bottom: 20px;
                        @include mobileXR{
                            font-size: 1rem;
                        }
                    }
                    .product_view_wrapper{
                        display: grid;
                        grid-template-columns: repeat(2,1fr);
                        gap: 10px;
                        @include mobileXR{
                            grid-template-columns: repeat(1,1fr);
                        }
                        .produit-view-control{
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            label{
                                font-size: .9rem;
                                color: #797979;
                            }
                            .produit_input{
                                padding: 8px 10px;
                                border: none;
                                outline: none;
                                border: 1px solid #c5c5c5;
                                border-radius: 5px;
                                &:focus{
                                    border: 1px solid rgba(36, 36, 250, 0.418);
                                }
                            }
                            .error-message{
                                color: red;
                            }
                        }
                    }
                    .produit_submit{
                        margin-top: 20px;
                        padding: 10px 15px;
                        border: none;
                        color: #fff;
                        background:rgb(1, 35, 138);
                        border-radius: 5px;
                        cursor: pointer;
                        transition: .5s;
                        &:hover{
                            background:rgb(2, 55, 214);
                        }
                    }
                }
            }
            .product-view-right{
                flex: 2;
                padding: 20px;
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                margin-bottom: 20px;
                border-radius: 5px;
                height: max-content;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .product-h2{
                    text-align: center;
                    font-size: 1.5rem;
                    padding-bottom: 20px;
                    color: #6d6c6c;
                }
                .product-img-row{
                    height: 250px;
                    width: 250px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px dashed #c5c5c5;
                    background: #f7f7f7;
                    border-radius: 3px;
                    margin-bottom: 30px;
                    .form-file{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        height:150px;
                        gap: 10px;
                        border-radius: 5px;
                        cursor: pointer;
                        &:focus{
                            border: 1px solid rgba(36, 36, 250, 0.418);
                        }
                        .cloud-icon{
                            color: rgba(14, 62, 204, 0.719);
                            font-size: 2.8rem;
                        }
                        span{
                            font-size: .8rem;
                            color: #929292;
                            letter-spacing: 1px;
                            text-align: center;
                        }
                    }
                    .product-img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
                .input-timeBar{
                    border: none;
                    background: #FFF;
                }
                .input-stock{
                    padding: 5px 10px;
                    border: none;
                    outline: none;
                    border: 1px solid #c5c5c5;
                    border-radius: 5px;
                    &:focus{
                        border: 1px solid rgba(36, 36, 250, 0.418);
                    }
                }
            }
        }
    }
}