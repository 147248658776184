@import '../../../style.scss';

.pageLivreurVente{
    @include mobileXR{
        width: 100%;
        @include mobileSE{
            width: 100%;
        }
    }
    .pageLivreurVente-container{
        @include mobileXR{
            padding: 20px;
            .page-rows-retour{
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(131, 129, 129, 0.75);
                font-size: 1.3rem;
                color: rgb(3, 3, 109);
                padding: 8px;
                border-radius: 5px;
                border: 1px solid rgba(3, 3, 109, 0.171);
                margin-bottom: 20px;
                text-align: center;
                width: max-content;
                cursor: pointer;
                .page-retour-row{
                    font-size: .8rem;
                }
            }
            h1{
                font-size: 1.4rem;
                color: rgb(3, 3, 109);
                padding: 20px 0;
                @include mobileSE{
                    font-size: 1.2rem;
                }
            }
            .pageH4{
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                font-size: 1.3rem;
                color: rgb(3, 3, 109);
                padding: 20px;
                border-radius: 5px;
                border: 1px solid rgba(3, 3, 109, 0.171);
                margin-bottom: 20px;
                text-align: center;
                @include mobileSE{
                    font-size: 1.1rem;
                }
            }
            .pageLivreur_info{
                background: #fff;
                    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    border-radius: 5px;
                    border: 1px solid rgba(3, 3, 109, 0.171);
            }
            .page-location{
                display: flex;
                justify-content: center;
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                padding: 20px;
                border-radius: 5px;
                border: 1px solid rgba(3, 3, 109, 0.171);
            }
        }
        .pageLivreur-call{
            @include mobileXR{
                padding: 20px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                .pageLivreur-call-row{
                    background: #fff;
                    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 20px;
                    border-radius: 5px;
                    border: 1px solid rgba(3, 3, 109, 0.171);
                    .pageLivreur-icon{
                        font-size: 1rem;
                        color: rgb(3, 3, 109);
                    }
                    .icon-whatsapp{
                        font-size: 1rem;
                        color: green;
                    }
                }
            }
        }
        .page-rows-top{
            @include mobileXR{
                margin: 20px 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
            }
            .page-row-top{
                @include mobileXR{
                    background: #fff;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    padding: 20px;
                    border: 1px solid rgba(3, 3, 109, 0.171);
                    border-radius: 5px;
                    cursor: pointer;
                    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    height: 80px;
                }
                .page-icon{
                    @include mobileXR{
                        height: 180px;
                        width: 230px;
                    }   
                }
                .page-top-desc{
                    @include mobileXR{
                        padding: 10px 0;
                        text-align: center;
                        font-size: 1.1rem;
                        font-weight: bolder;
                        color: #fff;
                        border-radius: 5px;
                        background: rgb(3, 3, 109);
                        width: 100%;
                        @include mobileSE{
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
        .rowChart-row-table{
            .userList{
                .userImg{
                    @include mobileXR{
                        position: relative;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        object-fit: cover;
    
                    }
                }
            }
        }
        .pageLivreur_submit{
            display: flex;
            flex-direction: column;
            gap: 15px;
            label{
                @include mobileXR{
                    font-size: .9rem;
                    color: #555;
                }
            }
            h2{
                @include mobileXR{
                    font-size: 1.5rem;
                    color: rgb(3, 3, 109);
                    @include mobileSE{
                        font-size: 1.3rem;
                    }

                }
            }
            .pageLivreur_dette{
                border: 1px solid #555;
                padding: 8px ;
                outline: none;
                &:hover{
                    border: 1px solid rgba(105, 140, 247, 0.801);
                }
            }
            .pageLivreur-input{
                width: 100%;
                border: 1px solid #555;
                padding: 10px ;
                outline: none;
                &:hover{
                    border: 1px solid rgba(105, 140, 247, 0.801);
                }
            }
            .pageLivreur_note{
                width: 100%;
                border: 1px solid #555;
                padding: 10px ;
                outline: none;
                resize: none;
                &:hover{
                    border: 1px solid rgba(105, 140, 247, 0.801);
                }
            }
        }
        .pageLivreur-form-rows{
            padding: 20px 0;
            @include mobileXR{
                display: flex;
                flex-direction: column;
                gap: 15px;
            }
            .pageLivreur-form-row{
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 10px;
                label{
                    @include mobileXR{
                        font-size: .9rem;
                        color: #555;
                    }
                }
                .page-select{
                    @include mobileXR{
                        width: 100%;
                        border: 1px solid #555;
                        padding: 10px ;
                    }
                }
                textarea{
                    @include mobileXR{
                        width: 100%;
                        border: 1px solid #555;
                        padding: 10px ;
                        resize: none;
                    }
                }
            }
            .pageLivreur-btn{
                border: none;
                padding: 10px;
                background:rgba(1, 35, 138, 0.952);
                color: #fff;
                &:hover{
                    border: 1px solid rgba(1, 35, 138, 0.952);
                    background: transparent;
                    color: rgba(1, 35, 138, 0.952);
                }
            }
        }
    }
}