@import '../../style.scss';

.products{
    .product-container{
        .product-container-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            border-radius: 5px;
            padding: 15px;
            @include miniTablette{
                display: grid;
                grid-template-columns: repeat(1,1fr);
                gap: 15px;
            }
            @include mobileXR{
                padding: 10px;
            }

            .product-left{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .product-h2{
                    font-size: 1rem;
                    color: rgb(1, 35, 138);
                    @include mobileXR{
                        font-size: .8rem;
                    }
                }
                span{
                    font-size: .8rem;
                    color: #6d6c6c;
                    @include mobileXR{
                        display: none;
                    }
                }
            }
            .product-right{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                background:rgb(1, 35, 138);
                color: #fff;
                padding: 10px;
                border-radius: 8px;
                cursor: pointer;
                .product-icon{
                    font-size: .9rem;
                }
                .product-btn{
                    font-size: .8rem;
                }
            }
        }
        .product-bottom{
            background: #fff;
            padding: 20px;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            .product-bottom-top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 30px;
                .product-bottom-left{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .product-icon{
                        color: #fff;
                        background:rgb(1, 35, 138);
                        padding: 10px;
                        border-radius: 5px;
                        font-size: 18px;
                        cursor: pointer;
                        transition: .5s;
                        &:hover{
                            background:rgb(6, 60, 223);
                        }
                    }
                    .product-icon2{
                        color: #fff;
                        background:rgba(255, 0, 0, 0.815);
                        padding: 10px;
                        border-radius: 5px;
                        font-size: 18px;
                        cursor: pointer;
                        @include miniTablette{
                            font-size: 16px;
                        }
                    }
                    .product-row-search{
                        border: 1px solid #c7c7c7;
                        border-radius: 5px;
                        padding: 7px 10px;
                        @include miniTablette{
                            padding: 6px 9px;
                        }
                        @include mobileXR{
                            padding: 6px 9px;
                        }
                        .product-h2{
                            color: rgb(1, 35, 138);
                            font-size: 1.5rem;
                            font-weight: 300;
                            @include mobileXR{
                                font-size: .7rem;
                            }
                        }
                        .product-search{
                            border: none;
                            outline: none;
                            padding-left: 10px;
                            @include miniTablette{
                                width: 120px;
                            }
                            @include mobileXR{
                                width: 110px;
                                padding-left: 8px;
                            }
                        }
                    }
                }
                .product-bottom-right{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    @include miniTablette{
                        display: none;
                    }
                    @include mobileXR{
                        display: none;
                    }
                    .product-icon-pdf{
                        color: red;
                        font-size: 22px;
                        cursor: pointer;
                    }
                    .product-icon-excel{
                        color: green;
                        font-size: 22px;
                        cursor: pointer;
                    }
                    .product-icon-printer{
                        font-size: 22px;
                        cursor: pointer;
                    }
                    .product-icon-raf{
                        background:rgba(1, 35, 138, 0.952);
                        padding: 10px;
                        background: #fff;
                        border-radius: 5px;
                        border: 1px solid #c2c2c2bb;
                        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                        border-radius: 5px;
                    }
                }
            }
            .rowChart-row-table{
                .userList{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .userImg{
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                    }
                }
            }
            .liste_bottom{
                margin: 20px 0;
                .liste_rows{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include mobileXR{
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .liste-row{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        label{
                            font-size: 1rem;
                            color: #555;
                        }
                        h3{
                            color: rgb(1, 35, 138);
                            font-size: 1.5rem;
                            font-weight: 300;
                        }
                        .list_select{
                            padding: 8px 10px;
                            width: 200px;
                            border: 1px solid #d6d6d6;
                            outline: none;
                            &:hover{
                                border: 1px solid rgb(78, 119, 241);
                            }
                        }
                    }
                    .rows-btn{
                        .list_btn{
                            padding: 10px 15px;
                            border: none;
                            background:rgba(1, 35, 138, 0.952);
                            color: #fff;
                            cursor: pointer;
                            transition: .5s;
                            &:hover{
                                border: 1px solid rgba(1, 35, 138, 0.952);
                                background: transparent;
                                color: rgba(1, 35, 138, 0.952);
                            }
                            @include mobileXR{
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}