@import '../../../style.scss';

.pageCommandeLivraison{
    @include mobileXR{
        padding: 20px;
        .pageCommande-return{
            @include mobileXR{
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                font-size: 1.3rem;
                color: rgb(3, 3, 109);
                padding: 6px;
                border-radius: 5px;
                border: 1px solid rgba(3, 3, 109, 0.171);
                width: max-content;
                cursor: pointer;
            }
            span{
                font-size: 13px;
            }
        }
        h1{
            color: rgb(3, 3, 109);
            font-size: 1.5rem;
            padding: 10px 0 20px 0;
            @include mobileSE{
                font-size: 1.2rem;
            }
        }
        .pageCommande-list{
            @include mobileXR{
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                font-size: 1.3rem;
                color: rgb(3, 3, 109);
                padding: 20px;
                border-radius: 5px;
                border: 1px solid rgba(3, 3, 109, 0.171);
                margin: 6px 0;
            }
        }
    }
}