/* @import url('https://fonts.googleapis.com/css2?family=Anton&family=Nunito:wght@200;300;400;500;600;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+3:wght@700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
a{
    color: black;
    text-decoration: none;
}
li{
    list-style: none;
}

.appContainer{
    display: flex;
    min-height: 100vh;
}
.appOutlet{
    position: relative;
    background: #fafafa;
    flex: 8;
    overflow: hidden;
}
.appOutlet{
    padding: 20px;
}

.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinnerContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
  .mission-icon{
    color: rgb(5, 97, 5);
    cursor: pointer;
  }

  .App {
    font-family: sans-serif;
    padding: 16px;
  }

  @media print {
    .print-button {
      display: none;
    }
  }
  
  .Crop-Controls {
    margin-bottom: 10px;
  }
  .Crop-Controls > * {
    margin-bottom: 3px;
  }
  
  img {
    max-width: 100%;
  }

/*   ::-webkit-scrollbar{
    width: .60rem;
    background: linear-gradient(#253053,#253053d7) no-repeat;
    z-index: -22;
}
::-webkit-scrollbar-thumb{
  background: linear-gradient(#253053,#253053d7) no-repeat;

}
::-webkit-scrollbar-thumb:hover{
  background: linear-gradient(#253053,#253053d7) no-repeat;
} */

.pageNavbar{
  display: flex;
  flex-direction: column;
  background: #fff;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  color: #000;
  text-decoration: none;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.pagination a:hover {
  background-color: #ccc;
}

.pagination .active a {
  background-color: #36d7b7;
  color: #fff;
}
.pointer-cursor {
  cursor: pointer;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loader-container-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rapport-vente-selects {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  z-index: 999;
}

.rapport-vente-selects.open {
  max-height: 500px; /* Hauteur maximale souhaitée */
}


.skeleton-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.skeleton-group {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.skeleton-item {
  flex: 1;
}

/* App.css */
.ant-tabs {
  margin-bottom: 20px;
}

.ant-card {
  margin-top: 20px;
}
