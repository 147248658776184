.cart-wrapper{
    .cart-container-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        padding: 20px;
        border-radius: 5px;
        .cart-left{
            .cart-h2{
                font-size: 1rem;
                color: rgb(1, 35, 138);
            }
            span{
                font-size: .8rem;
                color: #6d6c6c;
            }
        }
    }
    .cart-bottom{
        width: 100%;
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        padding: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .cart-bottom-rows{
            display: flex;
            gap: 30px;
            .cart-bottom-row{
                flex: 2;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .cart-bottom-left{
                    flex: 2;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #cfcfcf;
                    padding-bottom: 20px;
                    .cart-row-img{
                        width: 200px;
                        object-fit: cover;
                    }
                    .cart-row-center{
                        flex: 2;
                        display: flex;
                        flex-direction: column;
                        justify-content:space-around;
                        padding: 20px;
                        gap: 10px;
                        .cart-product-name{
                            color: rgba(1, 35, 138, 0.952);
                            display: flex;
                            gap: 5px;
                            
                        }
                        .cart-product-id{
                            display: flex;
                            gap: 5px;
                            color: rgba(1, 35, 138, 0.952);
                        }
                        .cart-product-color{
                            display: flex;
                            gap: 5px;
                            height: 20px;
                            width: 20px;
                            border-radius: 50%;
                            background: black;
                            .Noir{
                                height: 20px;
                                width: 20px;
                                background: black;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Gris{
                                height: 20px;
                                width: 20px;
                                background: gray;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Bleu{
                                height: 20px;
                                width: 20px;
                                background: blue;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Rouge{
                                height: 20px;
                                width: 20px;
                                background: red;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Vert{
                                height: 20px;
                                width: 20px;
                                background: green;
                                gap: 30px;
                                border-radius: 50%;
                            }
                            .Marron{
                                height: 20px;
                                width: 20px;
                                background: brown;
                                gap: 30px;
                                border-radius: 50%;
                            }
                        }
                        .cart-product-size{
                            display: flex;
                            gap: 5px;
                            color: rgba(1, 35, 138, 0.952);
                        }
                        .cart-product-price{
                            color: rgba(1, 35, 138, 0.952);
                            display: flex;
                            font-size: 30px;
                            font-weight: 200;
                            gap: 5px;
                        }
                    }
                    .cart-row-right{
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        .cart-amount{
                            display: flex;
                            align-items: center;
                            gap: 20px;
                            .product-icon{
                                cursor: pointer;
                                font-size: 20px;
                                color: rgba(1, 35, 138, 0.952);
                            }
                            .cart-product-amount{
                                font-size: 24px;
                                padding: 10px;
                                border: 1px solid rgba(1, 35, 138, 0.952);
                                margin: 10px 0;
                                border-radius: 30%;
                                color:rgba(1, 35, 138, 0.952);
                            }
                        }
                        .cart-product-prix{
                            color: rgba(1, 35, 138, 0.952);
                        }
                    }
                }
                .cart-hr{
                    background:#e6e6e6;
                    height: 1px;
                    width: 100%;
                }
            }
            .cart-bottom-rows{
                    flex: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 10px;
                .cart-bottom-right{
                    width: 100%;
                    border: 1px solid lightgray;
                    padding: 20px;
                    border-radius: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
                    height: max-content;
                    .cart-summary-title{
                        font-weight: 300;
                        font-size: 1.3rem;
                    }
                    .cart-summary-items{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .cart-summary-txt{
                            font-size: 18px;
                            font-weight: 300;
                            color: #929292;
                        }
                        .cart-black{
                            font-size: 18px;
                            font-weight: 300;
                            color: #929292;
                        }
                        .cart-summary-price{
                            font-size: 19px;
                            font-weight: 300;
                            color: #929292;
                        }
                    }
                    .cart-summary-btn{
                        border: none;
                        background: rgba(1, 35, 138, 0.952);
                        color: #fff;
                        padding: 10px;
                        cursor: pointer;
                        font-weight: 600;
                        transition: .5s;
                        &:hover{
                            background: transparent;
                            border: 1px solid rgba(1, 35, 138, 0.952);
                            color:rgba(1, 35, 138, 0.952);
                        }
                    }
                }
                .cart-client{
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    border: 1px solid lightgray;
                    padding: 20px;
                    border-radius: 30px;
                    label{
                        font-size: 15px;
                        color: #929292;
                        letter-spacing: 1.2;
                        font-weight: 300;
                    }
                    .cart-select{
                        border: 1px solid lightgray;
                        padding: 8px 10px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        outline: none;
                        border-radius: 10px;
                        option{
                            padding:10px 0;
                        }
                    }
                }
            }
        }
    }
}