@import '../../style.scss';

.rowTotalDetails{
    width: 100%;
    .rowTotalDetail-wrapper{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;
        flex-basis: calc(25% - 20px);
        @include mobileXR{
            display: grid;
            grid-template-columns: repeat(2,1fr);
        }
        .rowTotalDetail-row{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 20px;
            color: #fff;
            cursor: pointer;
            .rowTotalDetail-left{
                display: flex;
                flex-direction: column;
                gap: 5px;
                .rowTotal-h2{
                    font-size: 2rem;
                    @include miniTablette{
                        font-size: 1.5rem;
                    }
                }
                .rowTotal-span{
                    font-size: .9rem;
                    @include miniTablette{
                        font-size: .7rem;
                    }
                }
            }
            .rowTotalDetail-right{
                .rowTotalIcon{
                    height: 40px;
                    width: 40px;
                    @include miniTablette{
                        height: 35px;
                        width: 35px;
                    }
                }
            }
        }
    }
}