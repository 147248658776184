.ticket{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #f2f2f2;
    padding: 40px;
    .ticket-wrapper{
        width: 100%;
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        padding: 30px;
        .ticket-number{
            font-size: 10px;
        }
        .ticket-logo{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .ticket-img{
                width: 150px;
                height: 150px;
            }
            .ticket-titles{
                font-size: 12px;
                font-weight: 600;
                color: rgba(1, 13, 48, 0.952);
            }
            .ticket-title{
                font-size: 11px;
                font-weight: 400;
                color: rgba(1, 13, 48, 0.952);
            }
        }
        .ticket-contact{
            margin: 10px 0;
            border-top : 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            .ticket-contact-rows{
                display: flex;
                flex-direction: column;
                gap: 6px;
                padding: 10px 0;
                justify-content: flex-end;
                .ticket-contact-row{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .ticket-name{
                        font-size: 11px;
                        color: #555;
                    }
                    b{
                        font-size: 11px;
                        font-weight: 400;
                        color: #555;
                    }
                }
            }
        }
        .ticket-table{
            table{
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
                tr{
                    border: 1px solid #dddddd;
                    text-align: left;
                    padding: 8px;
                    &:nth-child(even) {
                        background-color: #dddddd;
                      }
                    th{
                        border: 1px solid #dddddd;
                        text-align: left;
                        padding: 8px;
                    }
                    td{
                        font-size: 11px;
                        padding: 8px;
                    }
                    .ticket-th{
                        font-weight: 800;
                        font-size: 11px;
                    }
                }
            }
        }
        .ticket-fin{
            padding: 10px 0;
            .ticket-desc{
                font-size: 11px;
                text-align: justify;
                color: rgba(1, 13, 48, 0.952);
            }
        }
    }
}