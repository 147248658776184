@import '../../style.scss';
.rowTotals{
    width: 100%;
    .rowTotal-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        @include mobileXR{
            flex-direction: column;
        }
        .rowTotal{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            border-radius: 5px;
            border: 1px solid #c2c2c2bb;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 20px;
            @include mobileXR{
                width: 100%
            }
            .rowTotal-left{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background: rgba(255, 166, 0, 0.137);
                .rowTotalIcon{
                    font-size: 1.5rem;
                    color: #fff;
                }
            }
            .rowTotal-right{
                display: flex;
                flex-direction: column;
                gap: 10px;
                h2{
                    font-size: 1.1rem;
                    color: rgb(1, 35, 138);
                }
                .rowTotal-span{
                    font-size: .7rem;
                    color: #797979;
                    letter-spacing: 1.3;
                }
            }
        }
        .rowTotals{
            width: 55px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 5px;
            border: 1px solid #c2c2c2bb;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 20px;
            border-radius: 50%;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
}