.rapportVente{
    .rapportVente-wrapper{
        .product-container-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            background: #fff;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 20px;
            border-radius: 5px;
            .product-left{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .product-h2{
                    font-size: 1rem;
                    color: rgb(1, 35, 138);
                }
                span{
                    font-size: .8rem;
                    color: #6d6c6c;
                }
            }
            .product-right{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .product-text{
                    font-size: 1rem;
                    color: #555;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                .rapport-select{
                    border: 1px solid #dadada;
                    padding: 8px 10px;
                    width: 200px;
                    border-radius: 5px;
                }
            }
        }
        .rapportVente-container{
            background: #fff;
            padding: 30px;
        }
    }
}