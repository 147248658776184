@import '../../style.scss';

.pageLivreur{
    @include mobileXR{
        width: 100%;
    }
    @include mobileSE{
        width: 100%;
    }
    .pageLivreur-container{
        @include mobileXR{
            height: calc(100vh - 80px);
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            overflow: hidden;
        }
/*         nav{
            @include mobileXR{
                width: 100%;
                position: sticky;
                top: 0;
                z-index: 111;
                box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.151);
                background:rgba(1, 35, 138, 0.952);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0;
                transition: .5s;
            }
            .navbar-wrapper{
                @include mobileXR{
                    width: 380px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .nav-logo{
                    .nav-img{
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                    }
                }
                .navbar-right{
                    @include mobileXR{
                        display: flex;
                        align-items: center;
                        gap: 15px;
                    }
                    .navbar-icon{
                        @include mobileXR{
                            font-size: 1.6rem;
                            color: #cccccc;
                        }
                    }
                    .navbar_username{
                        @include mobileXR{
                            font-size: 1.5rem;
                            color: #cccccc;
                        }
                    }
                }
            }
            
        } */
        .pageLivreur-wrapper{
            position: relative;
            width: 100%;
            margin: auto;
            @include mobile12pro{
                width: 340px;
                margin: auto;
            }
            @include mobileXR{
                width: 350px;
                @include mobileSE{
                    width: 100%;
                }
            }
            .pageLivreur-message{
                @include mobile12pro{
                    padding: 0 10px;
                }
                @include mobileXR{
                    cursor: pointer;
                    border: 1px solid rgba(1, 35, 138, 0.952);
                    width: 350px;
                    margin: auto;
                    padding: 10px;
                    border-radius: 5px;
                    @include mobileSE{
                        width: 330px;
                        font-size: .9rem;
                    }
                }
            }
            .pageLivreur-message-red{
                @include mobileXR{
                    border: 1px solid red;
                    width: 350px;
                    margin: auto;
                    padding: 10px;
                    border-radius: 5px;
                    text-align: center;
                    color: red;
                    @include mobile12pro{
                        width: 345px;
                    }
                    @include mobileSE{
                        width: 330px;
                        font-size: .9rem;
                    }
                }
            }
        }
    }
}